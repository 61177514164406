import { useReducer, useState } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import {
  Button,
  Flashbar,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  Modal,
  Pagination,
  Select,
  SpaceBetween,
  Table,
  TextFilter,
} from '@cloudscape-design/components'
import { useCollection } from '@cloudscape-design/collection-hooks'

import EmptyState from '../../shared/EmptyState'
import {
  getTaxList,
  handleExport,
  updateTax,
  fetchTaxIssuersList,
} from './_api'
import { TAXES_COLUMN_DEFINITIONS } from './_columns'

const FormInput = ({ label, value, setValue }) => {
  return (
    <FormField key={label} label={label}>
      <Input
        name={label}
        value={value}
        onChange={({ detail }) => {
          setValue({ [label]: detail.value })
        }}
        disabled={label === 'tax_record_id'}
      />
    </FormField>
  )
}

export default function Taxes() {
  const [modalEditVisible, setModalEditVisible] = useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formSubmissionError, setFormSubmissionError] = useState('')
  const [flashbarItems, setFlashbarItems] = useState([])
  const [selectedIssuer, setSelectedIssuer] = useState({
    label: 'All Issuers',
    value: '',
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [currentFilter, setCurrentFilter] = useState({})
  const [selectedIA, setSelectedIA] = useState('')
  const [SelectedTaxRecord, setSelectedTaxRecord] = useState('')
  const [sortingDescending, setSortingDescending] = useState(false)
  const [sortingColumn, setSortingColumn] = useState(
    TAXES_COLUMN_DEFINITIONS[0]
  )
  const [currentItem, setCurrentItem] = useReducer((prev, next) => {
    const newCurrentItem = { ...prev, ...next }

    // if validation needed -- don't forgot to do in UI also

    return newCurrentItem
  }, {})

  const queryClient = useQueryClient()
  const { isLoading, data } = useQuery({
    queryKey: [
      'taxes',
      { currentPage, filter: currentFilter, sortingDescending, sortingColumn },
    ],
    queryFn: () =>
      getTaxList({
        page: currentPage,
        perPage: 15,
        filter: currentFilter,
        sortingDescending,
        sortingColumn,
      }),
  })

  const issuerListQuery = useQuery({
    queryKey: ['issuer_list'],
    queryFn: fetchTaxIssuersList,
    staleTime: Infinity,
  })

  const { items, collectionProps, paginationProps } = useCollection(
    data?.tax_report || [],
    {
      pagination: { pageSize: 15 },
      sorting: { defaultState: { sortingColumn: TAXES_COLUMN_DEFINITIONS[0] } },
      selection: {},
    }
  )

  const handleSortingChange = (event) => {
    setSortingDescending(event.detail.isDescending)
    setSortingColumn(event.detail.sortingColumn)
    setCurrentPage(1)
  }

  const updateTaxMutation = useMutation({
    mutationFn: updateTax,
    onSuccess: (data, variables, context) => {
      const newBarId = `message_${flashbarItems.length + 1}`
      flashbarItems.push({
        type: 'success',
        content: `Tax: ${variables.id} ${
          isEmpty(currentItem) ? 'created' : 'updated'
        }.`,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => {
          setFlashbarItems((items) =>
            items.filter((item) => item.id !== newBarId)
          )
        },
        id: newBarId,
      })
      setIsFormSubmitted(false)
      setModalEditVisible(false)
      setIsSubmitting(false)
      setFlashbarItems(() => flashbarItems)
    },
    onError: (err, newTax, context) => {
      setFormSubmissionError(err.message)
      setIsSubmitting(false)
      queryClient.setQueryData(['taxes'], context.previousTaxes)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['taxes'] })
    },
  })

  const handleEditModal = (itemToEdit) => {
    setCurrentItem(itemToEdit)
    setIsSubmitting(false)
    setFormSubmissionError('')
    setModalEditVisible(true)
  }

  const handleEditSubmit = (e) => {
    e.preventDefault()
    const form = document.querySelector('#update-form-data')
    const inputs = form.querySelectorAll('input')
    const data = {}
    Array.from(inputs).forEach((item) => {
      data[item.name] = item.value
    })
    updateTaxMutation.mutate(data)
  }

  const handleExportClick = (e) => {
    e.preventDefault()
    handleExport(currentFilter)
      .then(() => {
        const newBarId = `message_${flashbarItems.length + 1}`
        const newItems = [...flashbarItems]
        newItems.push({
          type: 'success',
          content:
            'Export process has started.  Please check your email for a download link to the exported file.',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => {
            setFlashbarItems((items) =>
              items.filter((item) => item.id !== newBarId)
            )
          },
          id: newBarId,
        })
        setFlashbarItems(() => newItems)
      })
      .catch((err) => {
        const newBarId = `message_${flashbarItems.length + 1}`
        const newItems = [...flashbarItems]
        flashbarItems.push({
          type: 'error',
          content: err.message,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => {
            setFlashbarItems((items) =>
              items.filter((item) => item.id !== newBarId)
            )
          },
          id: newBarId,
        })
        setFlashbarItems(() => newItems)
      })
  }

  return (
    <>
      <Table
        {...collectionProps}
        items={items}
        columnDefinitions={TAXES_COLUMN_DEFINITIONS}
        empty={
          <EmptyState
            title="No Tax Reports found"
            subtitle="No tax reports to display."
            action={
              <Button
                onClick={() => {
                  setSelectedIssuer({
                    label: 'All Issuers',
                    value: '',
                  })
                  setSelectedIA('')
                  setSelectedTaxRecord('')
                  setCurrentFilter({})
                }}
              >
                Clear Filters
              </Button>
            }
          />
        }
        loading={isLoading}
        variant="full-page"
        selectionType="single"
        stripedRows
        ariaLabels={{
          selectionGroupLabel: 'Items selection',
          itemSelectionLabel: ({ selectedItems }, item) => {
            const isItemSelected = selectedItems.filter(
              (i) => i.name === item.name
            ).length
            return `${item.name} is ${isItemSelected ? '' : 'not '}selected`
          },
          tableLabel: 'Taxes table',
        }}
        header={
          <>
            <Flashbar items={flashbarItems} />
            <Header
              variant="h1"
              actions={
                <SpaceBetween size="xs" direction="horizontal">
                  <Button
                    iconName="edit"
                    onClick={() =>
                      handleEditModal(collectionProps.selectedItems[0])
                    }
                    disabled={collectionProps?.selectedItems?.length === 0}
                  >
                    Edit
                  </Button>
                  <Button
                    iconName="download"
                    disabled={isEmpty(currentFilter) || items?.length === 0}
                    onClick={handleExportClick}
                  >
                    Export
                  </Button>
                </SpaceBetween>
              }
            >
              Taxes
            </Header>
          </>
        }
        filter={
          <SpaceBetween direction="horizontal" size="m">
            <TextFilter
              filteringText={selectedIA}
              g
              filteringPlaceholder="IA ID"
              filteringAriaLabel="Filter by IA ID"
              onChange={({ detail }) =>
                setSelectedIA(detail.filteringText.replace(/\D/g, ''))
              }
            />
            <Select
              data-testid="issuer-filter"
              options={issuerListQuery.data || []}
              selectedAriaLabel="Selected"
              selectedOption={selectedIssuer}
              onChange={(event) => {
                setSelectedIssuer(event.detail.selectedOption)
              }}
              placeholder="Select an Issuer"
              ariaDescribedby={null}
              expandToViewport
            />
            <TextFilter
              filteringText={SelectedTaxRecord}
              filteringPlaceholder="Tax Record Id"
              filteringAriaLabel="Filter by Tax Record Id"
              onChange={({ detail }) =>
                setSelectedTaxRecord(detail.filteringText.replace(/\D/g, ''))
              }
            />
            <Button
              iconName="filter"
              onClick={() => {
                const updatedFilter = {}
                if (selectedIA) updatedFilter['ia_id'] = selectedIA
                if (selectedIssuer?.value)
                  updatedFilter['issuer_name'] = selectedIssuer.value
                if (SelectedTaxRecord)
                  updatedFilter['tax_record_id'] = SelectedTaxRecord
                setCurrentFilter(updatedFilter)
                setCurrentPage(1)
              }}
            >
              Filter
            </Button>
          </SpaceBetween>
        }
        onSortingChange={handleSortingChange}
        sortingColumn={sortingColumn}
        sortingDescending={sortingDescending}
        pagination={
          <Pagination
            {...paginationProps}
            pagesCount={Math.ceil(data?.total_records / 15)}
            currentPageIndex={currentPage}
            onChange={({ detail }) => setCurrentPage(detail.currentPageIndex)}
          />
        }
      />
      <Modal
        onDismiss={() => setModalEditVisible(false)}
        visible={modalEditVisible}
        closeAriaLabel="Close modal"
        size="large"
        header="Update Tax Record"
      >
        <form id="update-form-data" onSubmit={handleEditSubmit}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  formAction="submit"
                  loading={isSubmitting}
                  variant="primary"
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
            isFormSubmitted={isFormSubmitted}
            errorText={formSubmissionError}
          >
            <Grid
              gridDefinition={Array.apply(
                { colspan: 3 },
                { length: TAXES_COLUMN_DEFINITIONS.length }
              )}
            >
              {Object.entries(currentItem || []).map((item, index) => (
                <FormInput
                  key={index}
                  label={item[0]}
                  value={item[1]}
                  setValue={setCurrentItem}
                />
              ))}
            </Grid>
          </Form>
        </form>
      </Modal>
    </>
  )
}
