import React, { useEffect, useState } from 'react'
import { AppLayout, Button, TopNavigation } from '@cloudscape-design/components'
import { applyMode, Mode } from '@cloudscape-design/global-styles';
import { useQuery } from '@tanstack/react-query'
import { getUser, cognitoLogout } from '../shared/auth'
import './styles.css'
import '@cloudscape-design/global-styles/dark-mode-utils.css'

export default function Layout({
  children,
  contentType,
  breadcrumbs,
  tools,
  navigation,
  notifications,
}) {
  const { data } = useQuery({
    queryKey: ['auth'],
    queryFn: getUser,
  })

  const [topNavColor, setTopNavColor] = useState('')
  const [topNavTitle, setTopNavTitle] = useState('')
  const [showTopBar, setShowTopBar] = useState('flex')

  useEffect(() => {
    // keep dark mode preference in local storage
    if (window.localStorage.getItem('darkmode') === 'true') {
      applyMode(Mode.Dark);
    }
    switch (process.env.REACT_APP_API_BASE_URL) {
      case 'api.sandbox.realtymogul.com':
        setTopNavColor('brown')
        setTopNavTitle('RM Admin Portal (Sandbox)')
        if (process.env.NODE_ENV === 'development') {
          setTopNavColor('darkslateblue')
          setTopNavTitle('RM Admin Portal (localhost)')
        }
        break
      case 'api.develop.realtymogul.com':
        setTopNavColor('darkgreen')
        setTopNavTitle('RM Admin Portal (Develop)')
        break
      default:
        setShowTopBar('none')
    }
  }, [])

  const handleMenuClick = (e) => {
    switch (e.detail.id) {
      case 'sign-out':
        cognitoLogout()
        break
      default:
        console.log('item clicked:', e.detail.id)
    }
  }

  return (
    data && (
      <div>
        <div
          style={{
            width: '100%',
            padding: '15px 25px',
            height: '50px',
            boxSizing: 'border-box',
            background: topNavColor || '',
            display: showTopBar,
            justifyContent: 'space-between',
          }}
        >
          <div>{topNavTitle}</div>
          <Button
            onClick={() => {
              setShowTopBar('none')
            }}
            iconName="close"
            variant="inline-icon"
          />
        </div>
        <div id="top-nav">
          <TopNavigation
            identity={{
              title: 'RealtyMogul Admin Portal',
              href: '/',
            }}
            i18nStrings={{
              overflowMenuTriggerText: 'More',
              overflowMenuTitleText: 'All',
            }}
            utilities={[
              {
                type: 'button',
                text: 'Homepage',
                href: 'https://www.realtymogul.com',
                external: true,
                externalIconAriaLabel: ' (opens in a new tab)',
              },
              {
                type: 'button',
                text: 'Toggle Light/Dark mode',
                ariaLabel: 'Toggle Light/Dark mode',
                onClick: () => {
                  document.body.classList.contains('awsui-dark-mode') ? applyMode(Mode.Light) : applyMode(Mode.Dark)
                  if (window.localStorage.getItem('darkmode') !== 'true') {
                    window.localStorage.setItem('darkmode', 'true')
                  } else window.localStorage.setItem('darkmode', 'false')
                },
              },
              {
                type: 'menu-dropdown',
                text:
                  data?.signInUserSession?.idToken?.payload?.email ||
                  'RM Employee',
                iconName: 'user-profile',
                onItemClick: handleMenuClick,
                items: [
                  {
                    type: 'button',
                    id: 'sign-out',
                    text: 'Sign out',
                  },
                ],
              },
            ]}
          />
        </div>
        <AppLayout
          contentType={contentType}
          navigation={navigation}
          breadcrumbs={breadcrumbs}
          notifications={notifications}
          stickyNotifications={true}
          tools={tools}
          content={children}
          headerSelector="#top-nav"
          ariaLabels={{
            navigation: 'Navigation drawer',
            navigationClose: 'Close navigation drawer',
            navigationToggle: 'Open navigation drawer',
            notifications: 'Notifications',
            tools: 'Help panel',
            toolsClose: 'Close help panel',
            toolsToggle: 'Open help panel',
          }}
        />
      </div>
    )
  )
}
