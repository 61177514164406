import React from 'react'
import SideNavigation from '@cloudscape-design/components/side-navigation'
import { useNavigate } from '@tanstack/react-location'

const items = [
  { type: 'link', text: 'Tokens', href: '/tokens' },
  {
    type: 'section',
    text: 'Taxes',
    items: [
      { type: 'link', text: 'Reports', href: '/taxes/reports' },
      { type: 'link', text: 'Tax File Matching', href: '/taxes/file-matching' },
      { type: 'link', text: 'API Reference', href: '/taxes/api-reference' },
    ],
  },

  { type: 'link', text: 'Service Status', href: '/service' },
]

export default function Navigation() {
  const navigate = useNavigate()

  const handleClick = (e) => {
    e.preventDefault()
    navigate({ to: e.detail?.href, replace: true })
  }

  const pathname = window.location.pathname.includes('/taxes/file-matching')
    ? '/taxes/file-matching'
    : window.location.pathname

  return (
    <>
      <SideNavigation
        activeHref={pathname}
        header={{ href: '/', text: 'Dashboard' }}
        items={items}
        onFollow={handleClick}
      />
    </>
  )
}
