export const TAXES_COLUMN_DEFINITIONS = [
  {
    header: 'TAX RECORD ID',
    cell: ({ tax_record_id }) => tax_record_id,
    sortingField: 'tax_record_id',
  },
  {
    header: 'User ID',
    cell: ({ user_id }) => user_id,
    sortingField: 'user_id',
  },
  {
    header: 'First Name',
    cell: ({ userfirst_name }) => userfirst_name,
    sortingField: 'userfirst_name',
  },
  {
    header: 'Last Name',
    cell: ({ userlast_name }) => userlast_name,
    sortingField: 'userlast_name',
  },
  {
    header: 'Address Line 1',
    cell: ({ useraddress_thoroughfare }) => useraddress_thoroughfare,
    sortingField: 'useraddress_thoroughfare',
  },
  {
    header: 'Address Line 2',
    cell: ({ useraddress_street2 }) => useraddress_street2,
    sortingField: 'useraddress_street2',
  },
  {
    header: 'City',
    cell: ({ useraddress_locality }) => useraddress_locality,
    sortingField: 'useraddress_locality',
  },
  {
    header: 'State',
    cell: ({ useraddress_adminarea }) => useraddress_adminarea,
    sortingField: 'useraddress_adminarea',
  },
  {
    header: 'Zip',
    cell: ({ useraddress_postcode }) => useraddress_postcode,
    sortingField: 'useraddress_postcode',
  },
  {
    header: 'SSN/TIN',
    cell: ({ user_ssn_tin }) => user_ssn_tin,
    sortingField: 'user_ssn_tin',
  },
  {
    header: 'Joint First Name',
    cell: ({ joint_first_name }) => joint_first_name,
    sortingField: 'joint_first_name',
  },
  {
    header: 'Joint Last Name',
    cell: ({ joint_last_name }) => joint_last_name,
    sortingField: 'joint_last_name',
  },
  {
    header: 'IA ID',
    cell: ({ ia_id }) => ia_id,
    sortingField: 'ia_id',
  },
  {
    header: 'IA Title',
    cell: ({ ia_title }) => ia_title,
    sortingField: 'ia_title',
  },
  {
    header: 'Issuer Name',
    cell: ({ issuer_name }) => issuer_name,
    sortingField: 'issuer_name',
  },
  {
    header: 'FOE ID',
    cell: ({ userownership_entity_id }) => userownership_entity_id,
    sortingField: 'userownership_entity_id',
  },
  {
    header: 'FOE Name',
    cell: ({ userownership_entity_name }) => userownership_entity_name,
    sortingField: 'userownership_entity_name',
  },
  {
    header: 'FOE Type',
    cell: ({ userownership_entity_type }) => userownership_entity_type,
    sortingField: 'userownership_entity_type',
  },
  {
    header: 'FOE Subtype',
    cell: ({ userownership_entity_sub_type }) => userownership_entity_sub_type,
    sortingField: 'userownership_entity_sub_type',
  },
  {
    header: 'Retirement Funds',
    cell: ({ retirement_funds }) => retirement_funds,
    sortingField: 'retirement_funds',
  },
  {
    header: 'FOE TIN',
    cell: ({ foe_tin }) => foe_tin,
    sortingField: 'foe_tin',
  },
  {
    header: 'Is Disregarded Entity?',
    cell: ({ is_disregarded_entity }) => is_disregarded_entity,
    sortingField: 'is_disregarded_entity',
  },
  {
    header: 'Entity Name Override',
    cell: ({ entity_name_override }) => entity_name_override,
    sortingField: 'entity_name_override',
  },
  {
    header: 'Exempt Payee Code',
    cell: ({ exempt_payee_code }) => exempt_payee_code,
    sortingField: 'exempt_payee_code',
  },
  {
    header: 'Exemption from FATCA reporting code',
    cell: ({ exemption_from_fatca_reporting_code }) =>
      exemption_from_fatca_reporting_code,
    sortingField: 'exemption_from_fatca_reporting_code',
  },
  {
    header: 'Investment Amount',
    cell: ({ investment_amount }) => investment_amount,
    sortingField: 'investment_amount',
  },
  {
    header: 'Accrual Date',
    cell: ({ accrual_date }) => accrual_date,
    sortingField: 'accrual_date',
  },
  {
    header: 'Earnings Jan',
    cell: ({ earnings_jan }) => earnings_jan,
    sortingField: 'earnings_jan',
  },
  {
    header: 'Earnings Feb',
    cell: ({ earnings_feb }) => earnings_feb,
    sortingField: 'earnings_feb',
  },
  {
    header: 'Earnings Mar',
    cell: ({ earnings_mar }) => earnings_mar,
    sortingField: 'earnings_mar',
  },
  {
    header: 'Earnings Apr',
    cell: ({ earnings_apr }) => earnings_apr,
    sortingField: 'earnings_apr',
  },
  {
    header: 'Earnings May',
    cell: ({ earnings_may }) => earnings_may,
    sortingField: 'earnings_may',
  },
  {
    header: 'Earnings Jun',
    cell: ({ earnings_jun }) => earnings_jun,
    sortingField: 'earnings_jun',
  },
  {
    header: 'Earnings Jul',
    cell: ({ earnings_jul }) => earnings_jul,
    sortingField: 'earnings_jul',
  },
  {
    header: 'Earnings Aug',
    cell: ({ earnings_aug }) => earnings_aug,
    sortingField: 'earnings_aug',
  },
  {
    header: 'Earnings Sep',
    cell: ({ earnings_sep }) => earnings_sep,
    sortingField: 'earnings_sep',
  },
  {
    header: 'Earnings Oct',
    cell: ({ earnings_oct }) => earnings_oct,
    sortingField: 'earnings_oct',
  },
  {
    header: 'Earnings Nov',
    cell: ({ earnings_nov }) => earnings_nov,
    sortingField: 'earnings_nov',
  },
  {
    header: 'Earnings Dec',
    cell: ({ earnings_dec }) => earnings_dec,
    sortingField: 'earnings_dec',
  },
  {
    header: 'Earnings Jan+1',
    cell: ({ earnings_jan_plus_one }) => earnings_jan_plus_one,
    sortingField: 'earnings_jan_plus_one',
  },
  {
    header: 'Principal Jan',
    cell: ({ principal_jan }) => principal_jan,
    sortingField: 'principal_jan',
  },
  {
    header: 'Principal Feb',
    cell: ({ principal_feb }) => principal_feb,
    sortingField: 'principal_jan',
  },
  {
    header: 'Principal Mar',
    cell: ({ principal_mar }) => principal_mar,
    sortingField: 'principal_mar',
  },
  {
    header: 'Principal Apr',
    cell: ({ principal_apr }) => principal_apr,
    sortingField: 'principal_apr',
  },
  {
    header: 'Principal May',
    cell: ({ principal_may }) => principal_may,
    sortingField: 'principal_may',
  },
  {
    header: 'Principal Jun',
    cell: ({ principal_jun }) => principal_jun,
    sortingField: 'principal_jun',
  },
  {
    header: 'Principal Jul',
    cell: ({ principal_jul }) => principal_jul,
    sortingField: 'principal_jul',
  },
  {
    header: 'Principal Aug',
    cell: ({ principal_aug }) => principal_aug,
    sortingField: 'principal_aug',
  },
  {
    header: 'Principal Sep',
    cell: ({ principal_sep }) => principal_sep,
    sortingField: 'principal_sep',
  },
  {
    header: 'Principal Oct',
    cell: ({ principal_oct }) => principal_oct,
    sortingField: 'principal_jan',
  },
  {
    header: 'Principal Nov',
    cell: ({ principal_nov }) => principal_nov,
    sortingField: 'principal_nov',
  },
  {
    header: 'Principal Dec',
    cell: ({ principal_dec }) => principal_dec,
    sortingField: 'principal_dec',
  },
  {
    header: 'Principal Jan+1',
    cell: ({ principal_jan_plus_one }) => principal_jan_plus_one,
    sortingField: 'principal_jan_plus_one',
  },
]
