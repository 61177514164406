import { useQuery } from '@tanstack/react-query'
import {
  Button,
  Header,
  Table,
  TextFilter,
  SpaceBetween,
} from '@cloudscape-design/components'
import { useCollection } from '@cloudscape-design/collection-hooks'
import { Link } from '@tanstack/react-location'

import EmptyState from '../../shared/EmptyState'
import { fetchTaxIssuersList } from './_api'

export default function TaxFiles() {
  const ISSUER_LIST_COLUMN_DEFINITIONS = [
    {
      header: 'ID',
      cell: ({ id }) => id,
      sortingField: 'id',
      width: 65,
    },
    {
      header: 'Issuer',
      cell: ({ name }) => name,
      sortingField: 'name',
    },
    {
      header: 'Actions',
      cell: ({ id }) => (
        <Link to={`${id}`}>
          <Button data-id={id} iconName="edit">
            View Issuer
          </Button>
        </Link>
      ),
    },
  ]

  const { isLoading, data } = useQuery({
    queryKey: ['tax-issuers'],
    queryFn: fetchTaxIssuersList,
    staleTime: 60000,
  })

  const { items, filterProps, actions, collectionProps } = useCollection(
    data || [],
    {
      filtering: {
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={
              <Button onClick={() => actions.setFiltering('')}>
                Clear filter
              </Button>
            }
          />
        ),
        empty: (
          <EmptyState title="No issuers" subtitle="No issuers to display." />
        ),
      },
      sorting: {
        defaultState: { sortingColumn: ISSUER_LIST_COLUMN_DEFINITIONS[0] },
      },
      selection: {},
    }
  )

  return (
    <SpaceBetween size="m">
      <Table
        {...collectionProps}
        items={items}
        columnDefinitions={ISSUER_LIST_COLUMN_DEFINITIONS}
        stickyHeader={true}
        loading={isLoading}
        resizableColumns={true}
        variant="embedded"
        header={
          <Header variant="awsui-h1-sticky">Issuer Tax Process Status</Header>
        }
        filter={
          <TextFilter {...filterProps} filteringPlaceholder="Find Issuer" />
        }
      />
    </SpaceBetween>
  )
}
