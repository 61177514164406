import { Auth } from '@aws-amplify/auth'

const getCognitoPoolId = () => {
  let poolId = ''

  switch (window.location.origin) {
    case 'https://admin.realtymogul.com':
      poolId = '7uj4okpd83kr36ve5io22a9djp'
      break
    case 'https://admin.develop.realtymogul.com':
      poolId = '6tcqhchcuk71nju22ejtjk9kvn'
      break
    case 'https://admin.qa.realtymogul.com':
      poolId = '4d18vb9m2bla10c4pb1ehfv6sh'
      break
    default:
      // default poolId is for Sandbox/localhost
      poolId = '3pgvjvasju9310k2sbka7ftvvn'
  }

  return poolId
}

export const awsConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: getCognitoPoolId(),
    oauth: {
      domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
      scope: ['email', 'openid'],
      redirectSignIn: window.location.origin,
      redirectSignOut: window.location.origin,
      responseType: 'code',
    },
  },
}

export const getUser = () => {
  return Auth.currentAuthenticatedUser()
    .then((user) => user)
    .catch(() => {
      // if no current user, login
      Auth.federatedSignIn().then((user) => user)
    })
}

export const cognitoLogout = () => {
  Auth.signOut()
}
