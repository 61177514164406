import axios from 'axios'
import { Auth } from '@aws-amplify/auth'
import qs from 'qs'
import { isEmpty } from 'lodash'

const BASE_API_URL = `https://${process.env.REACT_APP_API_BASE_URL}`

const TAXES_REPORT_API = BASE_API_URL + '/taxes/tax_reports'
const TAXES_RECORD_API = BASE_API_URL + '/taxes/records'
const TAXES_ISSUERS_LIST_API = BASE_API_URL + '/taxes/issuer_list'
const TAXES_DOCS = BASE_API_URL + '/taxes/docs'

export const getTaxList = (params) => {
  return Auth.currentSession().then(async (authData) => {
    const { page, perPage, filter, sortingDescending, sortingColumn } = params
    const query = {
      page_num: page,
      records_per_page: perPage,
      order: sortingDescending ? 'DESC' : 'ASC',
      sort: sortingColumn.sortingField,
    }
    const URL_WITH_QUERY = `${TAXES_REPORT_API}?${qs.stringify(query)}`

    const response = isEmpty(filter)
      ? await axios.get(URL_WITH_QUERY, {
          headers: {
            Authorization: `Bearer ${authData.idToken.jwtToken}`,
          },
        })
      : await axios({
          method: 'POST',
          url: URL_WITH_QUERY,
          headers: { Authorization: `Bearer ${authData.idToken.jwtToken}` },
          data: {
            filter,
          },
        })
    return response.data
  })
}

export const handleExport = (filter) => {
  return Auth.currentSession().then(async (authData) => {
    return axios({
      method: 'POST',
      url: `${TAXES_REPORT_API}?is_exported=true`,
      headers: { Authorization: `Bearer ${authData.idToken.jwtToken}` },
      data: {
        user_email_address: authData.idToken.payload.email,
        filter,
      },
    })
  })
}

export const updateTax = (params) => {
  const { tax_record_id } = params
  return Auth.currentSession().then((authData) => {
    return axios({
      method: 'PUT',
      url: `${TAXES_RECORD_API}/${tax_record_id}`,
      headers: { Authorization: `Bearer ${authData.idToken.jwtToken}` },
      data: { ...params },
    })
  })
}

export const fetchTaxIssuersList = () =>
  Auth.currentSession().then((authData) => {
    return axios({
      method: 'GET',
      url: TAXES_ISSUERS_LIST_API,
      headers: { Authorization: `Bearer ${authData.idToken.jwtToken}` },
    }).then(({ data }) => {
      const newData = data.map((item) => {
        return {
          label: item,
          value: item,
        }
      })
      newData.unshift({ label: 'All Issuers', value: '' })
      return newData
    })
  })

export const getTaxesDocs = () => {
  return Auth.currentSession().then(async (authData) => {
    const response = await axios.get(TAXES_DOCS, {
      headers: {
        Authorization: `Bearer ${authData.idToken.jwtToken}`,
      },
    })
    return response.data
  })
}
