import { useState } from 'react'
import {
  BarChart,
  Box,
  Button,
  ColumnLayout,
  Container,
  Header,
  Link,
  SpaceBetween,
} from '@cloudscape-design/components'
import { useQuery } from '@apollo/client'
import { orderBy } from 'lodash'

import { client, OPEN_IO_QUERY } from './_apollo'
import RM_TECH_WHITE from './rm_tech_white.svg'
import RM_TECH_BLACK from './rm_tech_black.svg'
import './style.css'

export default function Root() {
  const [openInvestmentsCount, setOpenInvestmentsCount] = useState(0)
  const [openPledgeCount, setOpenPledgeCount] = useState(0)
  const [openFullyPledgedCount, setOpenFullyPledgedCount] = useState(0)
  const [openWaitlistCount, setOpenWaitlistCount] = useState(0)
  const [fundedData, setFundedData] = useState([])

  const { data } = useQuery(OPEN_IO_QUERY, {
    client,
    onCompleted: (data) => {
      let oiCount = 0
      let pCount = 0
      let fpCount = 0
      let wCount = 0
      const fundedArr = []
      data.IoListForGraphql.ios.items.map((item) => {
        switch (item?.statusLabel.toLowerCase()) {
          case 'open for investment':
            oiCount++
            fundedArr.push({
              x: item.name,
              y: item.progressBarPercent,
            })
            break
          case 'open for pledging':
            pCount++
            break
          case 'waitlist':
            wCount++
            break
          default:
            if (item?.fullyPledged) fpCount++
        }
        return item
      })
      setOpenInvestmentsCount(oiCount)
      setOpenPledgeCount(pCount)
      setOpenFullyPledgedCount(fpCount)
      setOpenWaitlistCount(wCount)
      setFundedData(orderBy(fundedArr, 'y', 'desc'))
    },
  })

  return data ? (
    <SpaceBetween size="xxl">
      <Container
        header={
          <Header variant="h2" description="Viewing data from open investments">
            Open Investments
          </Header>
        }
      >
        <ColumnLayout columns="4" variant="text-grid">
          <div>
            <Box variant="awsui-key-label">Open For Investment</Box>
            <Link variant="awsui-value-large">{openInvestmentsCount}</Link>
          </div>
          <div>
            <Box variant="awsui-key-label">Open for Pledging</Box>
            <Link variant="awsui-value-large">{openPledgeCount}</Link>
          </div>
          <div>
            <Box variant="awsui-key-label">Fully Pledged</Box>
            <Link variant="awsui-value-large">{openFullyPledgedCount}</Link>
          </div>
          <div>
            <Box variant="awsui-key-label">Waitlist</Box>
            <Link variant="awsui-value-large">{openWaitlistCount}</Link>
          </div>
        </ColumnLayout>
      </Container>
      <Container
        header={
          <Header
            variant="h2"
            description="Percentage funded by Open for Investment Opportunities"
          >
            Open for Investment Progress
          </Header>
        }
      >
        <BarChart
          series={[
            {
              title: 'Funded %',
              type: 'bar',
              valueFormatter: (e) => `${(100 * e).toFixed(0)}%`,
              data: fundedData,
            },
          ]}
          yDomain={[0, 1]}
          hideFilter
          hideLegend
          i18nStrings={{
            filterLabel: 'Filter displayed data',
            filterPlaceholder: 'Filter data',
            filterSelectedAriaLabel: 'selected',
            detailPopoverDismissAriaLabel: 'Dismiss',
            legendAriaLabel: 'Legend',
            chartAriaRoleDescription: 'line chart',
            yTickFormatter: (e) => `${(100 * e).toFixed(0)}%`,
          }}
          ariaLabel="Stacked, horizontal bar chart"
          errorText="Error loading data."
          height={300}
          horizontalBars
          loadingText="Loading chart"
          recoveryText="Retry"
          stackedBars
          xScaleType="categorical"
          yTitle="% Funded"
          empty={
            <Box textAlign="center" color="inherit">
              <b>No data available</b>
              <Box variant="p" color="inherit">
                There is no data available
              </Box>
            </Box>
          }
          noMatch={
            <Box textAlign="center" color="inherit">
              <b>No matching data</b>
              <Box variant="p" color="inherit">
                There is no matching data to display
              </Box>
              <Button>Clear filter</Button>
            </Box>
          }
        />
      </Container>
    </SpaceBetween>
  ) : (
    <Box>
        <div style={{ maxWidth: '800px', margin: '0 auto' }}>
          <img
            class="rm-tech-image awsui-util-show-in-dark-mode"
            src={RM_TECH_WHITE}
            alt="RM Technologies"
          />
          <img
            class="rm-tech-image awsui-util-hide-in-dark-mode"
            src={RM_TECH_BLACK}
            alt="RM Technologies"
          />
        </div>
      </Box>
  )
}
