import { useState } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import {
  Box,
  Button,
  Flashbar,
  Form,
  FormField,
  Header,
  Input,
  Modal,
  Table,
  Textarea,
  TextFilter,
  SpaceBetween,
} from '@cloudscape-design/components'
import { useCollection } from '@cloudscape-design/collection-hooks'

import EmptyState from '../../shared/EmptyState'
import { getTokens, updateToken, deleteToken } from './_api'

const columnDefinitions = [
  {
    header: 'Token ID',
    cell: ({ id }) => id,
    sortingField: 'id',
    minWidth: 175,
  },
  {
    header: 'Value',
    cell: ({ tokenValue }) => tokenValue,
    sortingField: 'tokenValue',
    minWidth: 180,
  },
]

const TokenTable = ({ data, loading }) => {
  const [modalEditVisible, setModalEditVisible] = useState(false)
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false)
  const [currentItem, setCurrentItem] = useState({})
  const [newTokenId, setNewTokenId] = useState('')
  const [newTokenValue, setNewTokenValue] = useState('')
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formSubmissionError, setFormSubmissionError] = useState('')
  const [flashbarItems, setFlashbarItems] = useState([])

  const queryClient = useQueryClient()

  const { items, filterProps, actions, collectionProps } = useCollection(data, {
    filtering: {
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
      empty: (
        <EmptyState
          title="No tokens"
          subtitle="No tokens to display."
          action={<Button>Add Token</Button>}
        />
      ),
    },
    pagination: { pageSize: 300 },
    sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
    selection: {},
  })

  const updateTokenMutation = useMutation({
    mutationFn: updateToken,
    onMutate: async (newToken) => {
      // optimistically update data
      await queryClient.cancelQueries({ queryKey: ['tokens', newToken.id] })
      const previousTokens = queryClient.getQueryData(['tokens'])
      const updatedTokens = [...previousTokens]
      const matchedIndex = updatedTokens.findIndex(
        (oldToken) => oldToken.id === newToken.id
      )

      if (matchedIndex !== -1) {
        updatedTokens[matchedIndex] = { ...newToken }
        queryClient.setQueryData(['tokens'], updatedTokens)
      }
      return { previousTokens }
    },
    onSuccess: (data, variables, context) => {
      const newBarId = `message_${flashbarItems.length + 1}`
      flashbarItems.push({
        type: 'success',
        content: `Token: ${variables.id} ${
          isEmpty(currentItem) ? 'created' : 'updated'
        }.`,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => {
          setFlashbarItems((items) =>
            items.filter((item) => item.id !== newBarId)
          )
        },
        id: newBarId,
      })
      setIsFormSubmitted(false)
      setModalEditVisible(false)
      setIsSubmitting(false)
      setFlashbarItems(() => flashbarItems)
    },
    onError: (err, newToken, context) => {
      setFormSubmissionError(err.message)
      setIsSubmitting(false)
      queryClient.setQueryData(['tokens'], context.previousTokens)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['tokens'] })
    },
  })

  const handleEditModal = (itemToEdit) => {
    setCurrentItem(itemToEdit)
    setIsSubmitting(false)
    setFormSubmissionError('')
    setNewTokenId(itemToEdit?.id)
    setNewTokenValue(itemToEdit?.tokenValue)
    setModalEditVisible(true)
  }

  const handleEditSubmit = (e) => {
    e.preventDefault()
    setIsFormSubmitted(true)
    if (isEmpty(newTokenId) || isEmpty(newTokenValue)) return
    setIsSubmitting(true)
    updateTokenMutation.mutate({
      id: newTokenId,
      tokenValue: newTokenValue,
    })
  }

  const deleteTokenMutation = useMutation({
    mutationFn: deleteToken,
    onMutate: async (tokenToDelete) => {
      // optimistically update data
      await queryClient.cancelQueries({
        queryKey: ['tokens', tokenToDelete.id],
      })
      const previousTokens = queryClient.getQueryData(['tokens'])
      const updatedTokens = [...previousTokens].filter(
        (item) => item.id !== tokenToDelete.id
      )
      queryClient.setQueryData(['tokens'], updatedTokens)
      return { previousTokens }
    },
    onSuccess: (data, variables, context) => {
      const newBarId = `message_${flashbarItems.length + 1}`
      flashbarItems.push({
        type: 'error',
        content: `Token: ${variables.id} deleted.`,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => {
          setFlashbarItems((items) =>
            items.filter((item) => item.id !== newBarId)
          )
        },
        id: newBarId,
      })
      setModalDeleteVisible(false)
      setFlashbarItems(() => flashbarItems)
    },
    onError: (err, tokenToDelete, context) => {
      setFormSubmissionError(err.message)
      setIsSubmitting(false)
      queryClient.setQueryData(['tokens'], context.previousTokens)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['tokens'] })
    },
  })

  const handleDeleteClick = (e) => {
    e.preventDefault()
    deleteTokenMutation.mutate({
      id: currentItem.id,
    })
  }

  return (
    <>
      <Table
        {...collectionProps}
        items={items}
        columnDefinitions={columnDefinitions}
        stickyHeader={true}
        resizableColumns={true}
        loading={loading}
        variant="full-page"
        selectionType="single"
        ariaLabels={{
          selectionGroupLabel: 'Items selection',
          itemSelectionLabel: ({ selectedItems }, item) => {
            const isItemSelected = selectedItems.filter(
              (i) => i.name === item.name
            ).length
            return `${item.name} is ${isItemSelected ? '' : 'not '}selected`
          },
          tableLabel: 'Tokens table',
        }}
        header={
          <>
            <Flashbar items={flashbarItems} />
            <Header
              variant="awsui-h1-sticky"
              actions={
                <SpaceBetween size="xs" direction="horizontal">
                  <Button
                    iconName="edit"
                    onClick={() =>
                      handleEditModal(collectionProps.selectedItems[0])
                    }
                    disabled={collectionProps?.selectedItems?.length === 0}
                  >
                    Edit
                  </Button>
                  <Button
                    iconName="remove"
                    onClick={() => {
                      setModalDeleteVisible(true)
                      setCurrentItem(collectionProps.selectedItems[0])
                    }}
                    disabled={collectionProps?.selectedItems?.length === 0}
                  >
                    Delete
                  </Button>
                  <Button onClick={() => handleEditModal({})} variant="primary">
                    Add Token
                  </Button>
                </SpaceBetween>
              }
            >
              Tokens
            </Header>
          </>
        }
        filter={
          <TextFilter {...filterProps} filteringPlaceholder="Find Token" />
        }
      />
      <Modal
        onDismiss={() => setModalEditVisible(false)}
        visible={modalEditVisible}
        closeAriaLabel="Close modal"
        size="medium"
        header={isEmpty(currentItem) ? 'Add token' : 'Edit token'}
      >
        <form onSubmit={handleEditSubmit}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  formAction="submit"
                  loading={isSubmitting}
                  variant="primary"
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
            errorText={formSubmissionError}
          >
            <SpaceBetween direction="vertical" size="l">
              <FormField
                label="Token ID"
                stretch={true}
                errorText={
                  isFormSubmitted &&
                  isEmpty(newTokenId) &&
                  'Token ID is required.'
                }
              >
                <Input
                  value={newTokenId}
                  disabled={!isEmpty(currentItem)}
                  onChange={({ detail }) => setNewTokenId(detail.value)}
                  type="text"
                />
              </FormField>
              <FormField
                label="Token Value"
                stretch={true}
                errorText={
                  isFormSubmitted &&
                  isEmpty(newTokenValue) &&
                  'Token Value is required.'
                }
              >
                <Textarea
                  value={newTokenValue}
                  onChange={({ detail }) => setNewTokenValue(detail.value)}
                />
              </FormField>
            </SpaceBetween>
          </Form>
        </form>
      </Modal>
      <Modal
        onDismiss={() => {
          setFormSubmissionError('')
          setModalDeleteVisible(false)
        }}
        visible={modalDeleteVisible}
        closeAriaLabel="Close modal"
        size="medium"
        header="Delete token"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <form onSubmit={handleDeleteClick}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button onClick={handleDeleteClick} variant="primary">
                        Submit
                      </Button>
                    </SpaceBetween>
                  }
                  errorText={formSubmissionError}
                />
              </form>
            </SpaceBetween>
          </Box>
        }
      >
        Delete token id: <b>{currentItem.id}</b>?
      </Modal>
    </>
  )
}

export default function Tokens() {
  const { isLoading, data } = useQuery({
    queryKey: ['tokens'],
    queryFn: getTokens,
    staleTime: 30000,
  })

  return <TokenTable data={data || []} loading={isLoading} />
}
