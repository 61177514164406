import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'
import { Spinner } from '@cloudscape-design/components'
import { useQuery } from '@tanstack/react-query'
import { getTaxesDocs } from './_api'
import './index.css'

export default function TaxReference() {
  const { isLoading, data } = useQuery({
    queryFn: getTaxesDocs,
    queryKey: ['taxdocs'],
    staleTime: 'Infinity',
  })
  return isLoading ? (
    <Spinner size="large" />
  ) : (
    <SwaggerUI spec={JSON.parse(data.body)} />
  )
}
