import { useQuery } from '@tanstack/react-query'

import {
  Box,
  Button,
  Header,
  Table,
  Popover,
  StatusIndicator,
} from '@cloudscape-design/components'

import { getService } from './_api'

const columnDefinitions = [
  {
    header: 'Name',
    cell: ({ serviceName, environment, lastUpdated }) => (
      <>
        <Box variant="p">
          {serviceName} - {environment}
        </Box>
        <Box variant="code">Last updated: {lastUpdated}</Box>
      </>
    ),
    sortingField: 'serviceName',
  },
  {
    header: 'Token',
    cell: ({ ServiceToken }) => (
      <span className="custom-wrapping">
        <Box margin={{ right: 'xxs' }} display="inline-block">
          <Popover
            size="medium"
            position="top"
            triggerType="custom"
            dismissButton={false}
            content={
              <StatusIndicator type="success">
                Token copied to clipboard
              </StatusIndicator>
            }
          >
            <Button
              variant="inline-icon"
              iconName="copy"
              onClick={() => {
                navigator.clipboard.writeText(ServiceToken)
              }}
            />
          </Popover>
        </Box>
        {ServiceToken}
      </span>
    ),
    sortingField: 'ServiceToken',
    maxWidth: 180,
  },
  {
    header: 'Git Repo',
    cell: ({ gitRepo }) => gitRepo,
    sortingField: 'gitRepo',
  },
  {
    header: 'Branch Name',
    cell: ({ gitBranch }) => gitBranch,
    sortingField: 'gitBranch',
  },
  {
    header: 'Logging Level',
    cell: ({ loggingLevel }) => loggingLevel,
    sortingField: 'loggingLevel',
  },
]

export default function Service() {
  const { isLoading, data } = useQuery({
    queryKey: ['service'],
    queryFn: getService,
    staleTime: 30000,
  })

  return (
    <Table
      items={data}
      loading={isLoading}
      columnDefinitions={columnDefinitions}
      stickyHeader={true}
      sortingDisabled={true}
      variant="embedded"
      ariaLabels={{
        tableLabel: 'Service table',
      }}
      header={
        <>
          <Header variant="awsui-h1-sticky">Service</Header>
        </>
      }
    />
  )
}
