import { useEffect, useState } from 'react'
import axios from 'axios'
import { Auth } from '@aws-amplify/auth'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import {
  Autosuggest,
  Box,
  Button,
  ColumnLayout,
  Flashbar,
  Form,
  FormField,
  Header,
  Modal,
  Table,
  Tabs,
  TextFilter,
  SpaceBetween,
  StatusIndicator,
} from '@cloudscape-design/components'
import { useCollection } from '@cloudscape-design/collection-hooks'
import { useMatch, Link } from '@tanstack/react-location'

import EmptyState from '../../shared/EmptyState'
import {
  deleteTaxfile,
  fetchTaxIssuersList,
  getAccounts,
  getOneAccount,
  getTaxfiles,
  handleUpload,
  removeMatch,
  sendMatches,
  updateMatch,
} from './_api'

const FOETable = ({
  data,
  flashbarItems,
  addFlashbarItem,
  issuerId,
  isLoading,
}) => {
  const FOEcolumnDefinitions = [
    {
      header: 'Form of Entity',
      cell: ({ userownership_entity_name, userownership_entity_type }) => (
        <>
          <p style={{ whiteSpace: 'normal' }}>{userownership_entity_name}</p>
          <Box variant="code">{userownership_entity_type}</Box>
        </>
      ),
      sortingField: 'userownership_entity_name',
    },
    {
      header: 'Status',
      cell: ({ file_id }) => (
        <StatusIndicator type={file_id ? 'success' : 'error'}>
          {file_id ? 'Matched' : 'Not Matched'}
        </StatusIndicator>
      ),
      sortingField: 'file_id',
    },
    {
      header: 'Sent Status',
      cell: ({ sentStatus }) => sentStatus,
      sortingField: 'sentStatus',
    },
    {
      header: 'Actions',
      cell: ({ file_id, id }) => (
        <>
          {file_id && (
            <SpaceBetween direction="horizontal" size="xs">
              <div
                onMouseUp={(e) => {
                  e.preventDefault()
                  if (e.button === 0 || e.button === 1) {
                    handleFileClick(file_id)
                  }
                }}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                <Button iconName="file" external variant="primary">
                  View Matched File
                </Button>
              </div>
              <Button
                onClick={() =>
                  removeMatchMutation.mutate({
                    id: file_id,
                    accountId: id,
                    errors: 'Manually Unmatched',
                  })
                }
              >
                Remove Match
              </Button>
            </SpaceBetween>
          )}
        </>
      ),
    },
  ]

  const queryClient = useQueryClient()

  const removeMatchMutation = useMutation({
    mutationFn: removeMatch,
    onSuccess: (data, variables, context) => {
      addFlashbarItem({
        type: 'success',
        content: `Match removed.`,
      })
    },
    onError: (err) => {
      addFlashbarItem({
        type: 'error',
        content:
          err.message || 'There was an error while trying to remove the match.',
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['tax-accounts', issuerId] })
    },
  })

  const handleFileClick = (file_id) => {
    const BASE_API_URL = `https://${process.env.REACT_APP_API_BASE_URL}`
    Auth.currentSession().then(async (authData) => {
      axios({
        method: 'GET',
        url: `${BASE_API_URL}/taxes/taxfiles/${file_id}/view`,
        headers: { Authorization: `Bearer ${authData.idToken.jwtToken}` },
      }).then(({ data }) => {
        window.open(data, '_blank')
      })
    })
  }

  const { items, filterProps, actions, collectionProps } = useCollection(
    data || [],
    {
      filtering: {
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={
              <Button onClick={() => actions.setFiltering('')}>
                Clear filter
              </Button>
            }
          />
        ),
        empty: (
          <EmptyState
            title="No FOEs"
            subtitle="No FOEs to display."
            action={
              <Link to="/taxes/file-matching">
                <Button iconName="arrow-left">Back to Issuer List</Button>
              </Link>
            }
          />
        ),
      },
      pagination: { pageSize: 300 },
      sorting: { defaultState: { sortingColumn: FOEcolumnDefinitions[0] } },
      selection: {},
    }
  )

  return (
    <>
      <Table
        {...collectionProps}
        items={items}
        columnDefinitions={FOEcolumnDefinitions}
        stickyHeader
        resizableColumns
        loading={isLoading}
        header={<Flashbar items={flashbarItems} />}
        ariaLabels={{
          selectionGroupLabel: 'Items selection',
          itemSelectionLabel: ({ selectedItems }, item) => {
            const isItemSelected = selectedItems.filter(
              (i) => i.name === item.name
            ).length
            return `${item.name} is ${isItemSelected ? '' : 'not '}selected`
          },
          tableLabel: 'Tokens table',
        }}
        filter={
          <TextFilter {...filterProps} filteringPlaceholder="Search FOEs" />
        }
      />
    </>
  )
}

const K1Table = ({
  data,
  foeData,
  flashbarItems,
  addFlashbarItem,
  issuerId,
  isLoading,
}) => {
  const [modalEditVisible, setModalEditVisible] = useState(false)
  const [currentItem, setCurrentItem] = useState()
  const [currentFileName, setCurrentFileName] = useState('')
  const [selectedFoe, setSelectedFoe] = useState('')
  const [selectedFoeData, setSelectedFoeData] = useState({})
  const [foeList, setFoeList] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formSubmissionError, setFormSubmissionError] = useState('')
  const [selectedSSN, setSelectedSSN] = useState('-')

  useEffect(() => {
    const FOE_LIST = (foeData || []).map((item) => {
      return {
        value: `${item.userownership_entity_name} - ${item.userownership_entity_type}`,
      }
    })

    setFoeList(FOE_LIST)
  }, [foeData])

  useEffect(() => {
    const MATCHED_FOE = foeData?.find((item) => {
      const str = `${item.userownership_entity_name} - ${item.userownership_entity_type}`
      return str === selectedFoe
    })
    setSelectedFoeData(MATCHED_FOE)
    if (MATCHED_FOE) {
      getOneAccount(MATCHED_FOE?.id).then((data) => {
        setSelectedSSN(data.foe_tin)
      })
    }
  }, [foeData, selectedFoe])

  const K1columnDefinitions = [
    {
      header: 'Filename',
      cell: ({ fileName, id }) => (
        <div
          onMouseUp={(e) => {
            e.preventDefault()
            if (e.button === 0 || e.button === 1) {
              handleFileClick(id)
            }
          }}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          <Button iconName="file" external variant="link">
            {fileName}
          </Button>
        </div>
      ),
      sortingField: 'fileName',
    },
    {
      header: 'Uploaded at',
      cell: ({ createdDate }) => {
        if (!createdDate) return
        let date = new Date(createdDate)
        return date.toLocaleString('en-US')
      },
      sortingField: 'createdDate',
    },
    {
      header: 'Notes',
      cell: ({ errors }) => <p style={{ whiteSpace: 'normal' }}>{errors}</p>,
      sortingField: 'errors',
    },
    {
      header: 'Actions',
      cell: ({ id, fileName }) => (
        <SpaceBetween size="s">
          <Button iconName="edit" onClick={() => handleEditModal(id, fileName)}>
            Match
          </Button>
          <Button
            iconName="delete-marker"
            onClick={() => deleteFileMutation.mutate({ id })}
          >
            DELETE FILE
          </Button>
        </SpaceBetween>
      ),
      width: 320,
    },
  ]

  const queryClient = useQueryClient()

  const handleFileClick = (id) => {
    const BASE_API_URL = `https://${process.env.REACT_APP_API_BASE_URL}`
    Auth.currentSession().then(async (authData) => {
      axios({
        method: 'GET',
        url: `${BASE_API_URL}/taxes/taxfiles/${id}/view`,
        headers: { Authorization: `Bearer ${authData.idToken.jwtToken}` },
      }).then(({ data }) => {
        window.open(data, '_blank')
      })
    })
  }

  const { items, filterProps, actions, collectionProps } = useCollection(
    data || [],
    {
      filtering: {
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={
              <Button onClick={() => actions.setFiltering('')}>
                Clear filter
              </Button>
            }
          />
        ),
        empty: (
          <EmptyState
            title="No K1s"
            subtitle="No K1s to display."
            action={
              <Link to="/taxes/file-matching">
                <Button iconName="arrow-left">Back to Issuer List</Button>
              </Link>
            }
          />
        ),
      },
      sorting: {
        defaultState: {
          sortingColumn: K1columnDefinitions[1],
          isDescending: true,
        },
      },
      selection: {},
    }
  )

  const deleteFileMutation = useMutation({
    mutationFn: deleteTaxfile,
    onSuccess: () => {
      addFlashbarItem({
        type: 'success',
        content: `Tax file deleted.`,
      })
    },
    onError: (err) => {
      addFlashbarItem({
        type: 'error',
        content:
          err.message || 'There was an error while trying to delete the file.',
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['tax-files', issuerId] })
    },
  })

  const updateMatchMutation = useMutation({
    mutationFn: updateMatch,
    onSuccess: () => {
      addFlashbarItem({
        type: 'success',
        content: `Tax file matching updated`,
      })
    },
    onError: (err) => {
      addFlashbarItem({
        type: 'error',
        content:
          err.message || 'There was an error while trying to update the match.',
      })
    },
    onSettled: () => {
      setModalEditVisible(false)
      queryClient.invalidateQueries({ queryKey: ['tax-files'] })
    },
  })

  const handleEditModal = (itemToEdit, fileName) => {
    setCurrentItem(itemToEdit)
    setCurrentFileName(fileName)
    setSelectedSSN('-')
    setIsSubmitting(false)
    setFormSubmissionError('')
    setModalEditVisible(true)
    setSelectedFoe('')
  }

  const handleEditSubmit = (e) => {
    e.preventDefault()
    updateMatchMutation.mutate({
      id: currentItem,
      accountId: selectedFoeData?.id,
      errors: !selectedFoeData.id ? 'Manually Unmatched' : '',
    })
  }

  return (
    <>
      <Table
        {...collectionProps}
        items={items}
        columnDefinitions={K1columnDefinitions}
        stickyHeader
        resizableColumns
        header={<Flashbar items={flashbarItems} />}
        loading={isLoading}
        ariaLabels={{
          selectionGroupLabel: 'Items selection',
          itemSelectionLabel: ({ selectedItems }, item) => {
            const isItemSelected = selectedItems.filter(
              (i) => i.name === item.name
            ).length
            return `${item.name} is ${isItemSelected ? '' : 'not '}selected`
          },
          tableLabel: 'Tokens table',
        }}
        filter={
          <TextFilter {...filterProps} filteringPlaceholder="Search K1s" />
        }
      />
      {data && (
        <Modal
          onDismiss={() => setModalEditVisible(false)}
          visible={modalEditVisible}
          closeAriaLabel="Close modal"
          size="large"
          header={
            <SpaceBetween direction="horizontal" size="xl">
              <span>Set manual match</span>
              <Button
                external
                onClick={() => {
                  handleFileClick(currentItem)
                }}
              >
                {currentFileName}
              </Button>
            </SpaceBetween>
          }
        >
          <form onSubmit={handleEditSubmit}>
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    formAction="submit"
                    loading={isSubmitting}
                    variant="primary"
                    disabled={!selectedFoe}
                  >
                    Submit
                  </Button>
                </SpaceBetween>
              }
              errorText={formSubmissionError}
            >
              <SpaceBetween direction="vertical" size="xxl">
                <FormField label="Match to FOE" stretch={true}>
                  <Autosuggest
                    onChange={({ detail }) => setSelectedFoe(detail.value)}
                    value={selectedFoe}
                    options={foeList}
                    enteredTextLabel={(value) => `Use: "${value}"`}
                    ariaLabel="Autosuggest example with suggestions"
                    placeholder="Enter value"
                    empty="No matches found"
                  />
                </FormField>
                <ColumnLayout columns={3} variant="text-grid">
                  <SpaceBetween size="l">
                    <div>
                      <Box variant="awsui-key-label">FOE Name</Box>
                      <div>
                        {selectedFoeData?.userownership_entity_name || '-'}
                      </div>
                    </div>
                    <div>
                      <Box variant="awsui-key-label">FOE Type</Box>
                      <div>
                        {selectedFoeData?.userownership_entity_type || '-'}
                      </div>
                    </div>
                  </SpaceBetween>

                  <SpaceBetween size="l">
                    <div>
                      <Box variant="awsui-key-label">FOE SSN/TIN</Box>
                      <div>{selectedSSN}</div>
                    </div>
                    <div>
                      <Box variant="awsui-key-label">
                        Is disregarded entity?
                      </Box>
                      <div>
                        {selectedFoeData?.is_disregarded_entity ? 'Yes' : 'No'}
                      </div>
                    </div>
                  </SpaceBetween>
                  <SpaceBetween size="l">
                    <div>
                      <Box variant="awsui-key-label">Street Name</Box>
                      <div>
                        {selectedFoeData?.useraddress_thoroughfare || '-'}
                      </div>
                    </div>
                    <div>
                      <Box variant="awsui-key-label">City, State, Zip</Box>
                      <div>
                        {selectedFoeData?.useraddress_locality
                          ? `${selectedFoeData?.useraddress_locality}, ${selectedFoeData?.useraddress_adminarea} ${selectedFoeData?.useraddress_postcode}`
                          : '-'}
                      </div>
                    </div>
                  </SpaceBetween>
                </ColumnLayout>
              </SpaceBetween>
            </Form>
          </form>
        </Modal>
      )}
    </>
  )
}

export default function Matches() {
  const [modalUploadVisible, setModalUploadVisible] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formSubmissionError, setFormSubmissionError] = useState('')
  const [file, setFile] = useState(null)
  const [error, setError] = useState(null)
  const [flashbarItems, setFlashbarItems] = useState([])

  const {
    params: { issuerId },
  } = useMatch()

  const TaxIssuersQuery = useQuery({
    queryKey: ['tax-issuers'],
    queryFn: fetchTaxIssuersList,
    staleTime: 600000,
  })

  const TaxfilesQuery = useQuery({
    queryKey: ['tax-files', issuerId],
    queryFn: () => getTaxfiles(issuerId),
    refetchInterval: 10000,
    refetchIntervalInBackground: true,
  })

  const FOEQuery = useQuery({
    queryKey: ['tax-accounts', issuerId],
    queryFn: () => getAccounts(issuerId),
    refetchInterval: 10000,
    refetchIntervalInBackground: true,
  })

  const openUploadModal = () => {
    setError(null)
    setFile(null)
    document.querySelector('#upload-file').value = null
    setFormSubmissionError('')
    setIsSubmitting(false)
    setModalUploadVisible(true)
  }

  const types = [
    'application/pdf',
    'application/zip',
    'application/x-zip-compressed',
  ]

  const handleChange = (e) => {
    let selected = e.target.files[0]

    if (selected && types.includes(selected.type)) {
      setFile(selected)
      setError(null)
    } else {
      setFile(null)
      setError('Please select a PDF or ZIP file')
    }
  }

  const handleUploadSubmit = (e) => {
    e.preventDefault()
    setFormSubmissionError('')
    setIsSubmitting(true)
    handleUpload(issuerId, file)
      .then(() => {
        addFlashbarItem({
          type: 'success',
          content: 'File has successfully been uploaded and is now processing.',
        })
        setModalUploadVisible(false)
      })
      .catch((err) => {
        setFormSubmissionError(err.message)
        setIsSubmitting(false)
      })
  }

  const addFlashbarItem = ({ content, type }) => {
    const newBarId = `message_${flashbarItems.length + 1}`
    // const newItems = [...flashbarItems]
    // replace latest
    const newItems = [
      {
        type,
        content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => {
          setFlashbarItems((items) =>
            items.filter((item) => item.id !== newBarId)
          )
        },
        id: newBarId,
      },
    ]
    setFlashbarItems(() => newItems)
  }

  const handleSendMatches = async (e) => {
    e.preventDefault()
    sendMatches(issuerId)
      .then(() => {
        addFlashbarItem({
          type: 'success',
          content: 'Matches have been sent and are now processing.',
        })
      })
      .catch((err) => {
        addFlashbarItem({
          type: 'error',
          content:
            err?.message ||
            'There has been an error.  Please contact a system administrator.',
        })
      })
  }
  return (
    <SpaceBetween size="m">
      <Link to="/taxes/file-matching">
        <Button iconName="arrow-left" variant="link">
          Back to Tax File Matching Issuer List
        </Button>
      </Link>
      <Header
        variant="awsui-h1-sticky"
        actions={
          <SpaceBetween size="xs" direction="horizontal">
            <Button iconName="upload" onClick={openUploadModal}>
              Upload
            </Button>
            <Button onClick={handleSendMatches}>Send Matches</Button>
          </SpaceBetween>
        }
      >
        Issuer:{' '}
        {TaxIssuersQuery.data && TaxIssuersQuery.data.find((ele) => ele.id === Number(issuerId))?.name}
      </Header>
      <Tabs
        tabs={[
          {
            label: 'FOEs',
            id: 'first',
            content: (
              <FOETable
                data={FOEQuery.data}
                taxfilesData={TaxfilesQuery.data}
                addFlashbarItem={addFlashbarItem}
                isLoading={FOEQuery.isLoading}
                flashbarItems={flashbarItems}
                issuerId={issuerId}
              />
            ),
          },
          {
            label: 'Unmatched Files',
            id: 'second',
            content: (
              <K1Table
                data={TaxfilesQuery?.data?.filter((item) => {
                  return !item?.accountId
                })}
                foeData={FOEQuery.data}
                isLoading={TaxfilesQuery.isLoading}
                addFlashbarItem={addFlashbarItem}
                flashbarItems={flashbarItems}
                issuerId={issuerId}
              />
            ),
          },
        ]}
      />
      <Modal
        onDismiss={() => setModalUploadVisible(false)}
        visible={modalUploadVisible}
        closeAriaLabel="Close modal"
        size="medium"
        header="Upload File"
      >
        <form onSubmit={handleUploadSubmit}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  formAction="submit"
                  loading={isSubmitting}
                  variant="primary"
                  disabled={error || !file}
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
            errorText={formSubmissionError}
          >
            <SpaceBetween direction="vertical" size="l">
              <FormField
                label="PDF or ZIP File"
                stretch={true}
                errorText={error}
              >
                <input
                  disabled={isSubmitting}
                  id="upload-file"
                  name="upload-file"
                  onChange={handleChange}
                  type="file"
                />
              </FormField>
            </SpaceBetween>
          </Form>
        </form>
      </Modal>
    </SpaceBetween>
  )
}
