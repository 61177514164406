import axios from 'axios'
import { Auth } from '@aws-amplify/auth'

const BASE_API_URL = `https://${process.env.REACT_APP_API_BASE_URL}`
const SERVICE_API = BASE_API_URL + '/serviceTracker/allServices'

export const getService = async () => {
  return Auth.currentSession().then(async (authData) => {
    const response = await axios.get(SERVICE_API, {
      headers: {
        Authorization: `Bearer ${authData.idToken.jwtToken}`,
      },
    })
    return response.data
  })
}
