import axios from 'axios'
import { Auth } from '@aws-amplify/auth'

const BASE_API_URL = `https://${process.env.REACT_APP_API_BASE_URL}/taxes`

const TAXES_FILES_API = BASE_API_URL + '/taxfiles'
const TAXES_ACCOUNTS_API = BASE_API_URL + '/accounts'
const TAXES_ISSUERS_LIST_API = BASE_API_URL + '/issuers'
const TAXES_MATCH_API = BASE_API_URL + '/match'
const TAXES_UNMATCH_API = BASE_API_URL + '/unmatch'

export const getAccounts = (issuerId) => {
  const URL_WITH_QUERY = `${TAXES_ACCOUNTS_API}?filter={'issuerId': ${issuerId}}`
  return Auth.currentSession().then(async (authData) => {
    return axios({
      method: 'GET',
      url: encodeURI(URL_WITH_QUERY),
      headers: { Authorization: `Bearer ${authData.idToken.jwtToken}` },
    }).then(({ data }) => data)
  })
}

export const getOneAccount = (id) => {
  const URL_WITH_QUERY = `${TAXES_ACCOUNTS_API}/${id}`
  return Auth.currentSession().then(async (authData) => {
    return axios({
      method: 'GET',
      url: encodeURI(URL_WITH_QUERY),
      headers: { Authorization: `Bearer ${authData.idToken.jwtToken}` },
    }).then(({ data }) => data)
  })
}

export const getTaxfiles = (issuerId) => {
  const URL_WITH_QUERY = `${TAXES_FILES_API}?filter={'issuerId': ${issuerId}}`
  return Auth.currentSession().then(async (authData) => {
    return axios({
      method: 'GET',
      url: encodeURI(URL_WITH_QUERY),
      headers: { Authorization: `Bearer ${authData.idToken.jwtToken}` },
    }).then(({ data }) => data)
  })
}

export const sendMatches = (issuerId) => {
  const URL_WITH_ISSUER_ID =
    BASE_API_URL + `/issuers/${issuerId}/send-matches`

  return Auth.currentSession().then(async (authData) => {
    return axios({
      method: 'POST',
      url: URL_WITH_ISSUER_ID,
      headers: { Authorization: `Bearer ${authData.idToken.jwtToken}` },
    }).then(({ data }) => data)
  })
}

export const deleteTaxfile = (params) => {
  const URL_WITH_TAXFILE_ID =
    TAXES_FILES_API + `/${params.id}`

  return Auth.currentSession().then(async (authData) => {
    return axios({
      method: 'DELETE',
      url: URL_WITH_TAXFILE_ID,
      headers: { Authorization: `Bearer ${authData.idToken.jwtToken}` },
    }).then(({ data }) => data)
  })
}

export const updateMatch = (params) => {
  const URL_WITH_QUERY = `${TAXES_MATCH_API}?tax_file_id=${params.id}&account_id=${params.accountId}`
  return Auth.currentSession().then((authData) => {
    return axios({
      method: 'PATCH',
      url: URL_WITH_QUERY,
      headers: { Authorization: `Bearer ${authData.idToken.jwtToken}` },
    })
  })
}

export const removeMatch = (params) => {
  const URL_WITH_QUERY = `${TAXES_UNMATCH_API}?tax_file_id=${params.id}`
  return Auth.currentSession().then((authData) => {
    return axios({
      method: 'PATCH',
      url: URL_WITH_QUERY,
      headers: { Authorization: `Bearer ${authData.idToken.jwtToken}` },
    })
  })
}

export const fetchTaxIssuersList = () => {
  return Auth.currentSession().then(async (authData) => {
    return axios({
      method: 'GET',
      url: TAXES_ISSUERS_LIST_API,
      headers: { Authorization: `Bearer ${authData.idToken.jwtToken}` },
    }).then(({ data }) => data)
  })
}

export const handleUpload = async (issuerId, file) => {
  const AuthResponse = await Auth.currentSession()
  const ProcessResponse = await axios({
    method: 'POST',
    url: BASE_API_URL + `/issuers/${issuerId}/process`,
    headers: { Authorization: `Bearer ${AuthResponse.idToken.jwtToken}` },
    data: { file_name: file.name },
  })
  const formData = new FormData()
  for (const key in ProcessResponse.data.fields) {
    formData.append(key, ProcessResponse.data.fields[key])
  }
  formData.append('Content-Type', file.type)
  formData.append('file', file)
  return axios({
    method: 'POST',
    url: ProcessResponse.data.url,
    data: formData,
  })
}
