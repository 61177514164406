/* eslint-disable */
import {
  HttpLink,
  InMemoryCache,
  ApolloLink,
  ApolloClient,
  gql
} from '@apollo/client'

export const OPEN_IO_QUERY = gql`
  query OpenIOs {
    IoListForGraphql(id: "00000181-ec7a-dd3c-afe7-efffa0ff0000") {
      internalname
      ios {
        items {
          ... on RealtymogulIoFlattened {
            blurb {
              text
            }
            city
            accrualType
            actualHold
            actualIrr
            actualMaturityDate
            address
            address1
            amountInvested
            assetCount
            companyName
            country
            desiredMinimumInvestment
            distributionFrequency
            distributionsSuspended
            escrowCloseDate
            estimatedCashOnCashLow
            estimatedHoldPeriod
            estimatedReturnHigh
            estimatedReturnLow
            fullyPledged
            fundraisingTarget
            fundsRaised
            highlights
            imageUrl
            images {
              ... on EmbeddedRealtymogulImage {
                __typename
                url
              }
              url
              embeddedRealtymogulImage {
                videoId
              }
              style
            }
            investmentName
            investmentStrategyLabel
            investmentStructure
            investmentType
            investmentTypeDisplay
            ioDetailType
            irrobjective
            issuer
            locality
            locationInformation
            locationMap
            manager
            minimum
            minimumIncrementValue
            name
            offeringCircular
            opportunityZone
            propertyClass
            propertyCount
            propertyLocation
            propertySummary
            propertyType
            quickLook1label
            registrationUrl
            reit
            returnObjective
            returnOnCost
            rmid
            securitiesOfferingTypes
            siteurl
            state
            statusLabel
            subscriptionAgreementOverride
            targetEquityMultiple
            targetHoldMax
            targetHoldMin
            targetMinimumHoldPeriodMonths
            targetReturn
            title
            title1
            title2
            title3
            totalPropertyPurchasePriceEstimatedValue
            yearStarted
            progressBarPercent
            progressBarTarget
            progressBarRaised
          }
        }
      }
      reits {
        items {
          ... on Reit {
            blurb {
              text
            }
            accrualType
            distributionFrequency
            estimatedReturnLow
            fundraisingTarget
            fundsRaised
            highlights
            idpAgreementModule
            image1
            image2
            image3
            investmentName
            investmentStructure
            investmentType
            ioDetailType
            issuer
            locality
            manager
            minimumIncrementValue
            name
            offeringCircular
            rmid
            securitiesOfferingTypes
            subscriptionAgreementOverride
            targetMinimumHoldPeriodMonths
            title1
            title2
            title3
            investModuleImage
            introTitle
            inputPlaceholderText
            incomeFactsheetCtaTitle
            incomeFactsheetCtaFile
            imageModalLarge
            imageModal
            image
            buttonText
            body1
            body2
            body3
            assetsDescription
            assetsDisclaimer
            assetCountOverride
            declaredDividendRateAnnualized
            disclaimersAndRiskFactorsTitle
            distributionSchedule
            distributionTooltip
            dripStatus
            expectedFirstDistribution
            expectedNextDistribution
            financials {
              text
            }
            introBlurb {
              text
            }
            investModuleImageCaption {
              text
            }
            investModuleTitle
            issuerRelationship
            label
            leadBlurb {
              text
            }
            leadImage
            management
            managerSelection
            navPrice
            navPriceDate
            numInvestors
            overview {
              text
            }
            propertyType
            purchasePrice
            purchasePriceDate
            reitTotalAssetValue
            reitpromoimageurl
            repeatMinimumInvestment
            source {
              text
            }
            statusValue
            targetMaxHolderPeriodMonths
            termsBlurb {
              text
            }
          }
        }
      }
    }
  }
`


const httpLink = new HttpLink({
  uri: 'https://www.realtymogul.com/graphql/delivery/rm-website-query-investment-opportunity-promos-delivery'
})

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'X-Client-ID': '00000180dce7db38adf9dfe72c4a0000',
      'X-Client-Secret': 'vepzMBnjSz1kLenA02fub2lItrWTZ8RJFSzovqx'
    }
  })

  // Call the next link in the middleware chain.
  return forward(operation)
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink)
})
