import axios from 'axios'
import { Auth } from '@aws-amplify/auth'

const BASE_API_URL = `https://${process.env.REACT_APP_API_BASE_URL}`
const TOKEN_API = BASE_API_URL + '/webtokens/admin'

export const getTokens = async () => {
  return Auth.currentSession().then(async (authData) => {
    const response = await axios.get(TOKEN_API, {
      headers: {
        Authorization: `Bearer ${authData.idToken.jwtToken}`,
      },
    })
    return response.data
  })
}

export const updateToken = (data) => {
  return Auth.currentSession().then((authData) => {
    return axios({
      method: 'PUT',
      url: TOKEN_API,
      headers: { Authorization: `Bearer ${authData.idToken.jwtToken}` },
      data: {
        TokenField: data.id,
        TokenValue: data.tokenValue,
      },
    })
  })
}

export const deleteToken = (data) => {
  return Auth.currentSession().then((authData) => {
    return axios({
      method: 'DELETE',
      url: TOKEN_API,
      headers: { Authorization: `Bearer ${authData.idToken.jwtToken}` },
      data: {
        TokenField: data.id,
      },
    })
  })
}
