import React from 'react'
import ReactDOM from 'react-dom/client'
import { AwsRum } from 'aws-rum-web'
import { Amplify } from '@aws-amplify/core'
import { Outlet, ReactLocation, Router } from '@tanstack/react-location'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { awsConfig } from './shared/auth'
import Layout from './shared/Layout'
import Navigation from './shared/Navigation'

import TaxReference from './routes/Taxes/TaxReference'
import Root from './routes/Root'
import Taxes from './routes/Taxes'
import TaxFiles from './routes/TaxFiles'
import Matches from './routes/TaxFiles/Matches'
import Tokens from './routes/Tokens'
import Service from './routes/Service'
import './index.css'

try {
  const config = {
    sessionSampleRate: 1,
    guestRoleArn:
      'arn:aws:iam::830361487402:role/RUM-Monitor-us-west-2-830361487402-5227479353761-Unauth',
    identityPoolId: 'us-west-2:b8f25906-21f3-44d2-8d58-ea6ca8926e44',
    endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
    telemetries: ['performance', 'errors', 'http'],
    allowCookies: true,
    enableXRay: true,
  }
  const APPLICATION_ID = '89a1b622-5ab6-464c-aa6d-4d5e362e32c9'
  const APPLICATION_VERSION = '1.0.0'
  const APPLICATION_REGION = 'us-west-2'
  // eslint-disable-next-line
  const awsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  )
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

Amplify.configure(awsConfig)

const ROUTES = [
  {
    path: '/',
    element: <Root />,
  },
  {
    path: '/tokens',
    element: <Tokens />,
  },
  {
    path: '/taxes/reports',
    element: <Taxes />,
  },
  {
    path: '/taxes/file-matching',
    children: [
      {
        path: '/',
        element: <TaxFiles />,
      },
      {
        path: ':issuerId',
        element: <Matches />,
      },
    ],
  },
  {
    path: '/taxes/api-reference',
    element: <TaxReference />,
  },
  {
    path: '/service',
    element: <Service />,
  },
  {
    element: 'page not found',
  },
]

const queryClient = new QueryClient()
const location = new ReactLocation()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router location={location} routes={ROUTES}>
        <Layout navigation={<Navigation />}>
          <Outlet />
        </Layout>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
)
